/* latin-ext */
@font-face {
  font-family: '__DM_Sans_294f17';
  font-style: normal;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/cc4e144c42392c7c-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_294f17';
  font-style: normal;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/4d34ec1d6c3c2732-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_294f17';src: local("Arial");ascent-override: 94.37%;descent-override: 29.49%;line-gap-override: 0.00%;size-adjust: 105.12%
}.__className_294f17 {font-family: '__DM_Sans_294f17', '__DM_Sans_Fallback_294f17', Poppins, system-ui, sans-serif;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Wix_Madefor_Display_178ba4';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(/_next/static/media/65b5a030ba741d0c.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Wix_Madefor_Display_178ba4';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(/_next/static/media/d1f41111c24ad988.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Wix_Madefor_Display_178ba4';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(/_next/static/media/b1f585e9a7b6aaf1.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Wix_Madefor_Display_178ba4';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(/_next/static/media/d752ec81a526f248.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Wix_Madefor_Display_178ba4';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(/_next/static/media/51620c4c525e73df.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}.__className_178ba4 {font-family: '__Wix_Madefor_Display_178ba4';font-style: normal
}

